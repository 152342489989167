// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
/* You can add global styles to this file, and also import other style files */
@import 'sadiant-mixin';
@import 'variable';
@import 'theme';

body {
  font-family: var(--font-family);
  font-weight: 400;
}
.card.card-cascade .view.gradient-card-header {
  padding: 1rem 1rem;
  text-align: center;
}

.card.card-cascade h3,
.card.card-cascade h4 {
  margin-bottom: 0;
}
.blue-gradient {
  background: -webkit-linear-gradient(50deg, #45cafc, #303f9f);
  background: linear-gradient(40deg, #45cafc, #4285f4);
}

.modal-full-height.modal-bottom > .modal-content {
  position: absolute !important;
  bottom: 0;
}

.modal-footer {
  justify-content: flex-start;
}

.z-index-1 {
  z-index: 1;
}

a {
  color: var(--primary-color);

  &:hover {
    color: var(--primary-dark-color);
  }
}

.fixed-sn main {
  min-height: 100vh;
  background-color: var(--surface-b);
}

.clinician-portal-skin {
  &.fixed-sn {
    background-color: var(--surface-b);
  }

  .btn {
    font-weight: bold;
    text-transform: none;
    padding: 10px 16px;

    // &.btn-default {
    //   background-color: #C1C6CE !important;
    // }
  }
}

.modal-open .fc-more-popover {
  display: none;
}

// GLOBAL STYLE
body {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--bluegray-900);
  text-align: left;
  background-color: var(--primary-color-text);
  font-family: var(--font-family);
  font-weight: 400;
}
// SITE CONTAINER
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.fixed-sn {
  main {
    padding-top: 5.5rem;
    min-height: 100vh;
    background-color: var(--surface-b);
    padding-left: 15rem;
  }
  footer {
    padding-left: 15rem;
  }
  .double-nav {
    padding-left: 15rem;
  }
}

@media (max-width: $lg) {
  .fixed-sn .double-nav,
  .fixed-sn footer,
  .fixed-sn main {
    padding-left: 0;
  }
}

@media (min-width: $md) {
  .fixed-sn .page-footer .container-fluid,
  .fixed-sn main {
    margin-left: 2%;
    margin-right: 2%;
  }
}

@media (min-width: $lg) {
  .fixed-sn .page-footer .container-fluid,
  .fixed-sn main {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (min-width: $xl) {
  .fixed-sn .page-footer .container-fluid,
  .fixed-sn main {
    margin-left: 6%;
    margin-right: 6%;
  }
}

// TYPOGRAPHY
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  color: var(--bluegray-900);
}
.h1,
h1 {
  font-size: 2.1875rem;
}
.h2,
h2 {
  font-size: 1.75rem;
}
.h3,
h3 {
  font-size: 1.53125rem;
}
.h4,
h4 {
  font-size: 1.3125rem;
}
.h5,
h5 {
  font-size: 1.09375rem;
}
.h6,
h6 {
  font-size: 0.875rem;
}

.newImmunization .inner-icon .view_more_btn,
.newImmunization .inner-icon .viewFile {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.newImmunization .inner-icon .view_more_btn {
  margin-right: 5px;
}
.newImmunization .uploadFileImgWrapper .uploadFilePdf {
  width: 100%;
}

// STATUS COLOR
.text-warning {
  color: var(--yellow-600);
}
.text-primary {
  color: var(--blue-600);
}
.text-danger {
  color: var(--red-500);
}

// pngo-css
.p-button {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);

  &:enabled:hover,
  &:enabled:active {
    background: var(--primary-dark-color);
    border-color: var(--primary-dark-color);
  }

  &:enabled:focus {
    box-shadow: 0 0 0 0.2rem var(--primary-300);
  }

  &.p-button-text {
    color: var(--primary-color);

    &:enabled:hover,
    &:enabled:active {
      background: var(--primary-300);
      color: var(--primary-color);
    }
  }

  &.p-button-link {
    color: var(--primary-color);

    &:enabled:hover,
    &:enabled:active {
      color: var(--primary-dark-color);
    }

    &:enabled:focus {
      box-shadow: 0 0 0 0.2rem var(--primary-300);
    }
  }

  &.p-button-danger {
    background: var(--red-500);
    border: 1px solid var(--red-500);
  }

  > .p-button-label {
    font-size: 14px;
    font-weight: 600;
  }

  &.p-button-secondary {
    &:enabled:hover {
      background-color: var(--text-color) !important;
      border-color: var(--text-color) !important;
      color: var(--primary-color-text) !important;
    }
  }
}

.p-inputswitch {
  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background-color: var(--primary-color) !important;
    }
  }
}

.p-selectbutton {
  .p-button {
    &.p-highlight {
      background: var(--primary-color);
      border: 1px solid var(--primary-color);
      color: var(--primary-color-text);
    }

    &.p-highlight {
      &:hover {
        background: var(--primary-color);
        border: 1px solid var(--primary-color);
        color: var(--primary-color-text);
      }
    }
  }

  &.p-buttonset.p-button-sm {
    > .p-button,
    > .p-button.p-button-sm {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      font-weight: 600;
      font-size: 12px;

      &.p-highlight {
        background: var(--primary-color);
        border-color: var(--primary-color);

        &:hover {
          background: var(--primary-dark-color);
          border-color: var(--primary-dark-color);
        }
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem var(--primary-300);
      }

      > .p-button-label {
        font-size: 12px;
      }
    }
  }
}

.p-dropdown {
  width: 100%;

  &:not(.p-disabled):hover {
    border-color: var(--primary-color);
  }

  &:not(.p-disabled).p-focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 0.2rem var(--primary-300);
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: var(--primary-color);
    color: var(--primary-color-text);
  }

  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding: 0.375rem 0.75rem;
  }
}

.p-multiselect {
  &:not(.p-disabled) {
    &:hover {
      border-color: var(--primary-color);
    }
  }
}

/********************* Input Text : START *********************/
.p-inputtext {
  width: 100%;

  &:enabled:hover {
    border-color: var(--primary-color);
  }
}

/********************* Input Text : END *********************/

.p-float-label {
  /** makes all inputs 14px */
  font-size: 14px;

  * {
    font-size: inherit;
  }

  input:focus ~ label,
  input.p-filled ~ label,
  textarea:focus ~ label,
  textarea.p-filled ~ label,
  .p-inputwrapper-focus ~ label,
  .p-inputwrapper-filled ~ label {
    font-weight: bold;
    top: -0.4rem;
  }

  p-dropdown {
    display: block;
    border-radius: 2px;

    & + label {
      margin-top: -0.4rem;
    }
  }
}

.p-calendar {
  width: 100%;

  > input.p-inputtext {
    width: 100%;
  }

  .p-datepicker {
    .p-datepicker-buttonbar {
      .p-button {
        background-color: transparent;
        border-color: transparent;
        color: var(--primary-color);

        &:hover {
          background-color: var(--primary-300);
        }
      }
    }

    table.p-datepicker-calendar {
      table-layout: 100%;

      th {
        text-align: center;
      }

      td > span {
        width: 100%;
        border-radius: 0;

        &.p-highlight {
          background: var(--primary-color);
          color: var(--primary-color-text);
        }

        &:focus {
          box-shadow: 0 0 0 0.2rem var(--primary-300);
        }
      }
    }

    .p-datepicker-header .p-datepicker-prev:focus,
    .p-datepicker-header .p-datepicker-next:focus {
      box-shadow: 0 0 0 0.2rem var(--primary-300);
    }
  }
}

.p-dialog-mask {
  padding-bottom: 20px;
  overflow-y: auto;
  z-index: 1200 !important;
  .p-dialog {
    height: auto;
    max-height: unset;
    margin-top: 20px;
    margin-bottom: auto;

    > .p-dialog-content {
      overflow: unset;
      padding: 0;
      border-radius: 3px;
    }
  }
}

/********************* Checkbox : START *********************/
.p-checkbox {
  .p-checkbox-box.p-highlight {
    border-color: var(--primary-color);
    background: var(--primary-color);
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &:hover {
        border-color: var(--primary-color);
      }

      &.p-focus {
        box-shadow: 0 0 0 0.2rem var(--primary-300);
      }
    }

    .p-checkbox-box.p-highlight {
      &:hover {
        border-color: var(--primary-dark-color);
        background: var(--primary-dark-color);
      }
    }
  }
}

/********************* Checkbox : END *********************/

/********************* Invalid Input : Start *********************/
.isInvalid {
  border-color: var(--red-500);

  & + label {
    color: var(--red-500);
  }

  .p-inputwrapper-focus {
    & + label {
      color: inherit !important;
    }
  }
}

.p-inputwrapper-focus {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 0.2rem var(--primary-300);
}

/********************* Invalid Input : End *********************/

.p-radiobutton {
  > .p-radiobutton-box {
    &.p-highlight {
      border-color: var(--primary-color);
      background: var(--primary-color);

      &:not(.p-disabled):hover {
        border-color: var(--primary-dark-color);
        background: var(--primary-dark-color);
      }
    }

    &:not(.p-disabled).p-focus {
      box-shadow: 0 0 0 0.2rem var(--primary-300);
    }
  }

  &.p-radiobutton-disabled {
    cursor: not-allowed;
  }
}

/********************* Prime-Flex Override: Start *********************/
.flex {
  display: flex;
}

.edit-cancel-buttons {
  > .edit-cancel-button {
    > .pi-pencil {
      color: var(--primary-color);
      cursor: pointer;
    }

    > .pi-trash {
      color: var(--red-500);
      cursor: pointer;
      margin-left: 12px;
    }
  }
}

.p-datatable-wrapper {
  td {
    word-wrap: break-word;
  }
}

/********************* Prime-Flex Override : End *********************/

// P COMPONENT STYLE OVERRIDE
.p-component {
  font-size: 0.875rem;
  font-family: var(--font-family);
  font-weight: 400;
}

/********************* Confirmation Dialog : Start  *********************/
p-confirmdialog {
  .p-dialog-content {
    padding: 5px 20px 5px 20px;
  }
}

/********************* Confirmation Dialog : Start  *********************/

// CARD
.p-card-title {
  .grid {
    margin-top: 0;
    margin-bottom: 0;

    & > div {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  color: var(--text-color) !important;
}
// p-dropdown.ng-dirty.ng-invalid>.p-dropdown {
//   border-color: var(--bluegray-200) !important;
// }
// p-dropdown.ng-dirty.ng-invalid.ng-touched>.p-dropdown,p-dropdown.ng-invalid.ng-touched>.p-dropdown, .ng-invalid.ng-touched span>input.p-autocomplete-input, .ng-invalid.ng-touched .p-calendar>input.p-inputtext {
//   border-color:var(--red-500) !important;
// }
.p-float-label > p-dropdown.ng-invalid.ng-dirty.ng-touched + label {
  color: var(--red-500);
}
.ng-invalid.ng-touched + label {
  color: var(--red-500);
}
// pngo-css-end

// after-theme-apply-BS-css
body {
  .text-primary {
    color: var(--green-500) !important;
  }
}
.p-datepicker {
  table {
    font-size: 1rem !important;
  }
}
.p-datatable {
  .p-sortable-column {
    &:not(.p-highlight) {
      .p-sortable-column-icon {
        color: var(--text-color-secondary) !important;
      }
    }
  }
  .p-datatable-thead {
    > tr {
      > th {
        background-color: var(--surface-b) !important;
      }
    }
  }
}
p-paginator {
  .p-link {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      Segoe UI Symbol !important;
    font-size: 16px !important;
  }
}
.p-multiselect {
  .p-multiselect-trigger {
    color: var(--text-color-secondary) !important;
    font-size: 0.875rem !important;
    .pi {
      color: var(--text-color-secondary) !important;
      font-size: 0.875rem !important;
    }
  }
}
.p-dropdown-trigger-icon {
  color: var(--text-color-secondary) !important;
  font-size: 0.875rem !important;
}
.p-button.p-button-secondary {
  background: var(--text-color-secondary) !important;
  border: 1px solid var(--text-color-secondary) !important;
}
.p-buttonset.p-button-secondary {
  > .p-button {
    background: var(--text-color-secondary) !important;
    border: 1px solid var(--text-color-secondary) !important;
  }
}
.p-splitbutton.p-button-secondary {
  > .p-button {
    background: var(--text-color-secondary) !important;
    border: 1px solid var(--text-color-secondary) !important;
  }
}
.p-inputtext.isInvalid {
  border-color: var(--red-500);
}
.p-inputtext.ng-invalid.ng-touched {
  border-color: var(--red-500);
  + {
    label {
      color: var(--red-500);
    }
  }
}
p-dropdown.ng-invalid.ng-touched {
  .p-dropdown {
    border-color: var(--red-500);
  }
  + {
    label {
      color: var(--red-500);
    }
  }
}
p-calendar.ng-invalid.ng-touched {
  .p-inputtext {
    border-color: var(--red-500);
  }
  + {
    label {
      color: var(--red-500);
    }
  }
}
.p-inputtext.isInvalid + label {
  color: var(--red-500);
}
p-dropdown.ng-dirty.ng-invalid {
  > .p-dropdown {
    color: var(--red-500);
  }
}
.p-float-label {
  > .ng-invalid.ng-dirty + label {
    color: var(--red-500);
  }
}
p-dropdown.ng-invalid.ng-touched.p-inputwrapper-focus {
  .p-dropdown {
    border-color: var(--primary-color) !important;
  }
  + {
    label {
      color: var(--text-color-secondary);
    }
  }
}
p-calendar.ng-invalid.ng-touched.p-inputwrapper-focus {
  .p-calendar {
    > input.p-inputtext {
      border-color: var(--primary-color) !important;
    }
  }
  + {
    label {
      color: var(--text-color-secondary);
    }
  }
}
.ng-invalid.ng-touched.ng-dirty {
  .p-calendar {
    > input.p-inputtext {
      border-color: #ced4da !important;
    }
  }
}
.p-button.subSectionButton {
  padding-left: 0px;
}
.credential-modal {
  .p-button.subSectionButton {
    padding-left: 0px;
  }
}

.overlapModal {
  .overlap-shift-table {
    width: 100%;
  }

  .shift_error {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 10px;
    border: 1px solid red;
    color: red;
    background-color: #ff000012;
    border-radius: 5px;
    font-weight: 700;
    letter-spacing: 0.7px;
    margin: 10px 0;
  }
}

// after-theme-apply-BS-css

// new-style-end
/* Header Notification Panel Start */

.notification-panel {
  max-width: 310px;
  width: 100%;
  &:after,
  &:before {
    left: auto !important;
    right: 0 !important;
  }
  .p-overlaypanel-content {
    padding: 5px !important;
  }
  .notification-wrap {
    h3 {
      font-size: 18px;
      border-bottom: 1px solid #dedede;
      padding: 10px;
      margin-bottom: 10px;
    }
    .notification-items {
      max-height: 360px;
      min-height: 250px;
      padding-right: 5px;
      overflow: auto;
      .no-message {
        text-align: center;
        font-weight: bold;
        font-size: 14px;
        padding: 5px;
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background-color: white;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background-color: #dddddd;
      }
    }
    .notification-box {
      background-color: #f4f4f4;
      padding: 10px;
      margin-bottom: 5px;
      cursor: pointer;
      border-radius: 5px;
      position: relative;
      p {
        margin: 0px;
        font-size: 12px;
        span {
          font-weight: bold;
        }
      }
    }
    .cancel-button-notification {
      padding: 0;
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 5px;
      .pi-times {
        font-size: 10px;
      }
    }
  }
}
/* Header Notification Panel End */

/* Zoom image preview modal */
.zoom-image-modal {
  max-width: 100%;
  width: 100% !important;
  box-shadow: none;
  margin-top: 0;
  &.p-dialog {
    height: 100%;
    margin: 0;
  }
  .p-dialog-content {
    margin: 0 auto;
  }
}
